import LeftNav from "../leftNav";
const Dashboard = ()=>{
    return(
        <div className="dashboard">
            <LeftNav />
        <h2>Welcome to dashboard!</h2>
        </div>
    )
}

export default Dashboard;