const Team = [
  // {
  //   id: 1,
  //   name: 'Dr. Andrew Davidoff',
  //   position: 'Founder/CTO',
  //   img_path: require('../../assets/img/team/Andrew.png'),
  //   category: 'capital',
  //   linkedIn: 'https://www.linkedin.com/in/andrew-joseph-davidoff/',
  //   region: 'USA'
  // },
  {
    id: 2,
    name: "Dr. Jay Patel",
    position: "Chairman & CEO",
    img_path: require("../../assets/img/team/jayy-1.png"),
    category: "capital",
    linkedIn: "https://www.linkedin.com/in/jay-patel-77795910b/",
    region: "USA",
  },
  // {
  //   id: 3,
  //   name: 'Dr. Michael Shoemaker',
  //   position: 'Geophysics Advisor',
  //   img_path: require('../../assets/img/team/michael.jpg'),
  //   category: 'capital',
  //   linkedIn: 'https://www.linkedin.com/in/dr-michael-shoemaker-71366531/',
  //   region: 'USA'
  // },
  {
    id: 4,
    name: "Darrell Gordon",
    position: "Chief Financial Officer",
    img_path: require("../../assets/img/team/darrell.jpg"),
    category: "royalties",
    linkedIn: "https://www.linkedin.com/in/darrell-gordon-204871a8/",
    region: "USA",
  },
  // {
  //   id: 5,
  //   name: 'Mike Fenton',
  //   position: 'Chief Engineering Officer',
  //   img_path: require('../../assets/img/team/mike.jpg'),
  //   category: 'royalties',
  //   linkedIn: 'https://www.linkedin.com/in/mike-fenton-7484a148/',
  //   region: 'USA'
  // },
  // {
  //   id: 6,
  //   name: 'Shane Quimby',
  //   position: 'Chief Compliance Officer',
  //   img_path: require('../../assets/img/team/Shane.png'),
  //   category: 'royalties',
  //   linkedIn: 'https://www.linkedin.com/in/shane-quimby-561097a/',
  //   region: 'USA'
  // },
  {
    id: 7,
    name: "Doug Campbell",
    position: "Advisor",
    img_path: require("../../assets/img/team/Doug.png"),
    category: "innovation",
    linkedIn: "https://www.linkedin.com/in/doug-campbell/",
    region: "USA",
  },
  // {
  //   id: 8,
  //   name: 'Frank Monachelli',
  //   position: 'Financial Advisor',
  //   img_path: require('../../assets/img/team/Frank.png'),
  //   category: 'innovation',
  //   linkedIn: 'https://www.linkedin.com/in/frankmonachelli/',
  //   region: 'USA'
  // },
  // {
  //   id: 9,
  //   name: 'Trey Storer',
  //   position: 'Business Development Advisor',
  //   img_path: require('../../assets/img/team/treyy.png'),
  //   category: 'e&p',
  //   linkedIn: 'https://www.linkedin.com/in/trey-storer/',
  //   region: 'USA'
  // },
  {
    id: 10,
    name: "Manish Mishra",
    position: "Business Development Advisor",
    img_path: require("../../assets/img/team/manish.png"),
    category: "e&p",
    linkedIn: "https://www.linkedin.com/in/manishmishraenergyallied/",
    region: "USA",
  },
  // {
  //   id: 11,
  //   name: 'Curt Horne',
  //   position: 'Senior Land expert',
  //   img_path: require('../../assets/img/team/1.png'),
  //   category: 'e&p',
  //   linkedIn: 'https://www.linkedin.com/in/curthorne/',
  //   region: 'USA'
  // },
  {
    id: 12,
    name: "Mohan Anem",
    position: "Senior Advisor, Corporate Finance",
    img_path: require("../../assets/img/team/Mohan.png"),
    category: "e&p",
    linkedIn: "https://www.linkedin.com/in/mohananem/",
    region: "Europe",
  },
  // {
  //   id: 12,
  //   name: "Shannon Caraway",
  //   position: "Senior Business Development Manager",
  //   img_path: require("../../assets/img/team/shannon.png"),
  //   category: "e&p",
  //   linkedIn: "https://www.linkedin.com/in/shannon-caraway-01a0661a/",
  //   region:"USA"

  // },
  // {
  //   id: 13,
  //   name: 'Keith Simpson',
  //   position: 'Senior Advisor',
  //   img_path: require('../../assets/img/team/keith.png'),
  //   category: 'e&p',
  //   linkedIn: '',
  //   region: 'USA'
  // },
  {
    id: 14,
    name: "Deepak Sharma",
    position: "International Strategic Advisor",
    img_path: require("../../assets/img/team/deepak.png"),
    category: "e&p",
    linkedIn: "https://www.linkedin.com/in/deepak-sharma-4b21a1181/",
    region: "USA",
  },
  // {
  //   id: 16,
  //   name: "Kunal Sharma",
  //   position: "International Financial Advisor",
  //   img_path: require("../../assets/img/team/kunal.png"),
  //   category: "e&p",
  //   linkedIn: "",
  //   region:"Europe"

  // },
  // {
  //   id: 15,
  //   name: 'Raj Patel',
  //   position: 'Out Reach Expert',
  //   img_path: require('../../assets/img/team/Raj_Patel.jpg'),
  //   category: 'e&p',
  //   linkedIn: 'https://www.linkedin.com/in/rajpatelprofessional/',
  //   region: 'India'
  // },
  // {
  //   id: 18,
  //   name: "Harish Mamtani",
  //   position: "Out Reach Expert",
  //   img_path: require("../../assets/img/team/Harish_Mamtani.png"),
  //   category: "e&p",
  //   linkedIn: "https://www.linkedin.com/in/harishmamtani/",
  //   region:"USA"

  // },

  {
    id: 16,
    name: "Stuart Cohen",
    position: "Financial Advisor",
    img_path: require("../../assets/img/team/stuart.jpg"),
    category: "e&p",
    linkedIn: "https://www.linkedin.com/in/stuart-jay-cohen",
    region: "USA",
  },
  // {
  //   id: 17,
  //   name: 'Vaibhav Patel',
  //   position: 'Operations',
  //   img_path: require('../../assets/img/team/vaibhav.png'),
  //   category: 'e&p',
  //   linkedIn: '',
  //   region: 'India'
  // },
  {
    id: 18,
    name: "Aaron Dirks",
    position: "Operations",
    img_path: require("../../assets/img/team/aaron.jpg"),
    category: "e&p",
    linkedIn: "https://www.linkedin.com/in/aarondirks/",
    region: "USA",
  },
  {
    id: 19,
    name: "Troy von Otnott",
    position: "Operations",
    img_path: require("../../assets/img/team/von.jpg"),
    category: "e&p",
    linkedIn: "https://www.linkedin.com/in/troy-von-otnott-60bbb86/",
    region: "USA",
  },
  // {
  //   id: 20,
  //   name: 'Floyd Griffith',
  //   position: 'Financial Advisor',
  //   img_path: require('../../assets/img/team/floyd.png'),
  //   category: 'e&p',
  //   linkedIn: 'https://www.linkedin.com/in/floyd-h-griffith-7016a811b/',
  //   region: 'USA'
  // },
  {
    id: 21,
    name: "Gabriela Balderas",
    position: "Paralegal",
    img_path: require("../../assets/img/team/Gabriela_Balderas.png"),
    category: "e&p",
    linkedIn: "https://www.linkedin.com/in/gabriela-balderas-11a796162/",
    region: "USA",
  },
  {
    id: 22,
    name: "Ashima Chock",
    position: "Senior Legal Council",
    img_path: require("../../assets/img/team/ashima_arora.png"),
    category: "e&p",
    linkedIn: "https://www.linkedin.com/in/ashimachock/",
    region: "USA",
  },
  // {
  //   id: 23,
  //   name: 'Jeremy Goodwin',
  //   position: 'Project Manager',
  //   img_path: require('../../assets/img/team/jeremy.jpg'),
  //   category: 'e&p',
  //   linkedIn: 'https://www.linkedin.com/in/jeremy-goodwin-a64140269/',
  //   region: 'USA'
  // },
  // {
  //   id: 24,
  //   name: 'Manisha Gandhi',
  //   position: 'Communication',
  //   img_path: require('../../assets/img/team/manisha.jpg'),
  //   category: 'e&p',
  //   linkedIn: 'https://www.linkedin.com/in/manishacgandhi/',
  //   region: 'USA'
  // },
  //  {
  //   id: 25,
  //   name: "Ahmed Malik",
  //   position: "Energy Advisor",
  //   img_path: require("../../assets/img/team/ahmed.jpg"),
  //   category: "e&p",
  //   linkedIn: "https://www.linkedin.com/in/ahmed-malik-229a4617/",
  //   region:"USA"

  // },
  // {
  //   id: 26,
  //   name: 'Tony Shaw',
  //   position: 'Geotechnical Advisor',
  //   img_path: require('../../assets/img/team/tony.png'),
  //   category: 'e&p',
  //   linkedIn: 'https://www.linkedin.com/in/tonyshaw/',
  //   region: 'USA'
  // },
  {
    id: 27,
    name: "Mital Patel",
    position: "Operations",
    img_path: require("../../assets/img/team/mital.jpg"),
    category: "e&p",
    linkedIn: "https://www.linkedin.com/in/mittal0801/",
    region: "Canada",
  },
  // {
  //   id: 28,
  //   name: 'Jigish Dharaiya',
  //   position: 'Operations',
  //   img_path: require('../../assets/img/team/jigish_dharaiya.png'),
  //   category: 'e&p',
  //   linkedIn: 'https://www.linkedin.com/in/jigish-dharaiya-66802950/',
  //   region: 'India'
  // }
  // {
  //   id: 29,
  //   name: "Mohit Shrivastav",
  //   position: "Operations",
  //   img_path: require("../../assets/img/team/Mohit_Shrivastav.jpg"),
  //   category: "e&p",
  //   linkedIn: "https://www.linkedin.com/in/jigish-dharaiya-66802950/",
  //   region: "India",
  // },
];

export default Team;
